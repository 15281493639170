import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { NormalizedProduct } from '@ts/product';
import { getAllBaseFrames } from '@services/contentful';

const useBaseFrameProducts = (skipVariants = true): UseQueryResult<NormalizedProduct[]> => {
	return useQuery(['base-frame-products'], async () => {
		return await getAllBaseFrames();
	});
};

export default useBaseFrameProducts;
