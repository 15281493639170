import React, { memo } from 'react';
import { Heading, Paragraph } from '@components';
import Img from '../Img';
import styles from './PictureGrid.module.scss';

type PictureGridProps = {
	caption?: string;
	src?: string;
	title?: string;
};

const PictureGrid = ({
	caption = 'Our lenses are polarized and filter 100% of UVA & UVB rays to keep your eyes protected. Goodbye glare and eye-strain—Hello Pair.',
	src = 'https://cdn.shopify.com/s/files/1/1147/9910/files/lens-upgrade-picture-grid.gif?v=1657120807',
	title = 'Upgrade your Lenses',
}: PictureGridProps) => {
	return (
		<section className={styles.container} data-section-name='picture-grid'>
			<div className={styles['text-container']}>
				<Heading tag='h2'>{title}</Heading>
				<Paragraph style={{ maxWidth: '42rem' }}>{caption}</Paragraph>
			</div>
			<div className={styles['image-container']}>
				<Img className={styles.image} src={src} noSrcset alt={title} sizes='100vw' />
			</div>
		</section>
	);
};

export default memo(PictureGrid);
